<script setup>
import { nextTick } from 'vue'
import { ChevronUpIcon } from '@heroicons/vue/24/outline';
import { TransitionRoot, TransitionChild } from '@headlessui/vue';
import { useNavigationStore } from '../../js/store/navigation';

const navigationStore = useNavigationStore()

const showMenu = async () => {
  await nextTick()

  document.querySelector('.jsh-nav').classList.toggle('hidden')
}
</script>
<template>
  <div class="hidden fixed top-0 left-0 bottom-0 z-30 lg:relative lg:flex flex-col w-48 min-w-48 bg-primary divide-y divide-white transition-all delay-75 duration-300 ease-in-out jsh-nav">
    <h1 class="text-white font-bold font-racingsans text-2xl hover:scale-110 transition-all delay-75 duration-300 ease-in-out">
      <router-link to="/" class="flex w-full px-2 py-2 justify-center">
        School go
      </router-link>
    </h1>

    <button class="lg:hidden p-1 rounded-full text-primary bg-gray-100 absolute top-9 -right-3 border border-primary" @click.prevent="showMenu">
      <ChevronUpIcon class="w-4 h-4 -rotate-90" />
    </button>

    <nav class="text-white select-none transition-all delay-75 duration-300 ease-in-out overflow-y-auto">
      <ul class="flex flex-col divide-y divide-white transition-all delay-75 duration-300 ease-in-out">
        <template v-for="item in navigationStore.items">
          <li 
            v-if="item.children"
            class="transition-all delay-75 duration-300 ease-in-out p-2 cursor-pointer group" 
          >
            <div v-if="item.children && item.children.length > 1"
              class="flex items-center justify-between font-bold"
              @click="navigationStore.toogleParent(item.key)"
            >
              {{ item.text }}
              <ChevronUpIcon 
                class="w-5 h-5 transition-all delay-75 duration-300 ease-in-out" 
                :class="{
                  'rotate-90': !navigationStore.current.parents.includes(item.key),
                  'rotate-180': navigationStore.current.parents.includes(item.key),
                }"
              />
            </div>
            <router-link v-else 
              :to="item.children[0].to"
              class="flex items-center justify-between font-bold"
            >
              {{ item.text }}
              <span v-show="navigationStore.current.childs.includes(item.children[0].to)"
                class="w-1.5 h-1.5 rounded-full bg-primary-500/50 shadow transition-all duration-300 delay-75 ease-in-out"
              ></span>
            </router-link>

            <TransitionRoot :show="navigationStore.current.parents.includes(item.key) && item.children && item.children.length > 1">
              <TransitionChild
                enter="transition ease-in-out duration-300 transform"
                enter-from="-translate-y-2"
                enter-to="translate-y-0"
                leave="transition ease-in-out duration-300 transform"
                leave-from="translate-y-0"
                leave-to="-translate-y-2"
              >
                <div 
                  class="flex-col divide-y divide-white/30 bg-white/20 rounded-lg overflow-hidden transition-all delay-75 duration-300 ease-in-out mt-2"
                >
                  <div v-for="child in item.children"
                    class="transition-all delay-75 duration-300 ease-in-out" 
                    :class="{
                      'hover:bg-primary-dark/80': !navigationStore.current.childs.includes(child.to),
                      'bg-white/40 text-primary-500/80': navigationStore.current.childs.includes(child.to),
                    }"
                    @click.prevent="navigationStore.setActiveChild(child.to)"
                  >
                    <router-link :to="child.to" class="flex w-full p-2 items-center justify-between">
                      {{ child.text }}
                      <span v-show="navigationStore.current.childs.includes(child.to)"
                        class="w-1.5 h-1.5 rounded-full bg-primary-500/50 shadow transition-all duration-300 delay-75 ease-in-out"
                      ></span>
                    </router-link>
                  </div>
                </div>
              </TransitionChild>
            </TransitionRoot>
          </li>
        </template>
      </ul>
    </nav>
  </div>
</template>
