import './bootstrap'
import './error'

// Vuejs
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createNotivue } from 'notivue'
import route from './route'

import 'notivue/notification.css'
import 'notivue/notification-progress.css'
import 'notivue/animations.css'

import App from '../views/App.vue'
import { createI18n } from 'vue-i18n'
import en from './locales/en.json'
import ja from './locales/ja.json'
import vi from './locales/vi.json'

const pinia = createPinia()
const i18n = createI18n({
  legacy: false,
  locale: 'ja',
  fallbackLocale: 'ja',
  messages: {
    ja: ja,
    en: en,
    vi: vi
  }
})

createApp(App)
  .use(createNotivue({ 
    position: 'top-right', 
    duration: 5000,
    pauseOnHover: false,
  }))
  .use(pinia)
  .use(route)
  .use(i18n)
  .mount('#app')
